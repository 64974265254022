import React from "react";
import { Link } from "react-router-dom";
import { useTheme } from "../context/themeContext";

import "./styles/navBar.css";

const NavBar = (props) => {
	const { toggleTheme } = useTheme();
	const { active } = props;

	return (
		<React.Fragment>
			<div className="nav-container">
				<nav className="navbar">
					<div className="nav-background">
						<ul className="nav-list">
							<li
								className={
									active === "home"
										? "nav-item active"
										: "nav-item"
								}
							>
								<Link to="/">Home</Link>
							</li>
							<li
								className={
									active === "about"
										? "nav-item active"
										: "nav-item"
								}
							>
								<Link to="/about">About</Link>
							</li>
							<li
								className={
									active === "projects"
										? "nav-item active"
										: "nav-item"
								}
							>
								<Link to="/projects">Projects</Link>
							</li>
							<li
								className={
									active === "blogs"
										? "nav-item active"
										: "nav-item"
								}
							>
								<Link to="/blogs">Blogs</Link>
							</li>
							<li
								className={
									active === "contact"
										? "nav-item active"
										: "nav-item"
								}
							>
								<Link to="/contact">Contact</Link>
							</li>
						</ul>
					</div>
				</nav>
			</div>
			<label id="switch" className="switch">
				<input type="checkbox" onClick={toggleTheme} id="slider" />
				<span className="slider round"></span>
			</label>
		</React.Fragment>
	);
};

export default NavBar;
